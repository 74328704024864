import React from "react";
import classes from "./Resources.module.scss";

const Resources = props => {
	return (
		<div className={classes.Resources}>
			<div>
				<a
						href="https://docs.google.com/presentation/d/e/2PACX-1vQWdIPVu4BNEn-dyLz3x3aIevrZJFstCwrJte9CwOiPpAZDbi6wAFNbAP93XYu8n5TFvzFWEHGcj7oa/pub?start=false&loop=false&delayms=3000"
						className={classes.Resources__link}
						target="_blank"
						rel="noopener noreferrer"
					>Ten Bulls
					</a>
				<iframe src="https://docs.google.com/presentation/d/e/2PACX-1vQWdIPVu4BNEn-dyLz3x3aIevrZJFstCwrJte9CwOiPpAZDbi6wAFNbAP93XYu8n5TFvzFWEHGcj7oa/embed?start=false&loop=false&delayms=3000" frameborder="0" width="960" height="569" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true" className={classes.Resources_ten_bulls}></iframe>
				<a
						href="https://peace-in-baltimore-audio.s3.amazonaws.com/3.7.m4a"
						className={classes.Resources__link}
						target="_blank"
						rel="noopener noreferrer"
					>Yoga Sutra 3.7: trayam antar angam purvebhyah
					</a>
			
				<audio controls className={classes.Resources_audio}>
						<source src="https://peace-in-baltimore-audio.s3.amazonaws.com/3.7.m4a" type="audio/mp3" />
						<p>Your browser doesn't support HTML5 audio. Here is a <a href="https://peace-in-baltimore-audio.s3.amazonaws.com/3.7.m4a">link to the audio</a> instead.</p>
				</audio>
			</div>
		
			<div className={classes.Resources__youtube__link}>
				<a
					href="https://www.youtube.com/watch?v=Y87Ktcar6R4"
					className={classes.Resources__link}
					target="_blank"
					rel="noopener noreferrer"
				>
					Full Yoga Sutras Chant
				</a>
				<div className={classes.Resources__youtube}>
					<iframe
						src="https://www.youtube.com/embed/Y87Ktcar6R4"
						frameBorder="0"
						allow="autoplay; encrypted-media"
						allowFullScreen
						title="video"
						width="100%"
						height="100%"
					/>
				</div>
			</div>
			<div className={classes.Resources__YC}>
				<a
					href="https://docs.google.com/document/d/e/2PACX-1vSVb2T5fwj04cqMzeFt_7yXminR6bO6S3Jx2uU6SvT_uUNA9vZ_Bz-zkG707siYOfn7O7q4B-vj6x-K/pub"
					className={
						classes.Resources__link +
						" " +
						classes.Resources__link__YC
					}
					target="_blank"
					rel="noopener noreferrer"
				>
					Our Yoga Sutra Interpretations
				</a>
				<div className={classes.Resources__google_doc}>
					<iframe
						src="https://docs.google.com/document/d/e/2PACX-1vSVb2T5fwj04cqMzeFt_7yXminR6bO6S3Jx2uU6SvT_uUNA9vZ_Bz-zkG707siYOfn7O7q4B-vj6x-K/pub?embedded=true"
						height="100%"
						width="100%"
						title="Yoga Sutras"
					></iframe>
				</div>
			</div>
			<a
				href="https://swamij.com/yoga-sutras.htm"
				className={classes.Resources__link}
				target="_blank"
				rel="noopener noreferrer"
			>
				SwamiJ Sutra Interpretations
			</a>
		</div>
	);
};

export default Resources;
